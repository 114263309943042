.back-btn{
    position: relative;
    top:4rem;
    text-decoration:none;
    color:black
}
.back-btn:hover{
    text-decoration:none;
    color:black
}
@media (max-width: 767px) { 
    .back-btn {
        display: none;
    }
 }
.continue-btn{
    width: 100% !important;
    border: 2px solid #31A9F3 !important;
    background-color: #FFFFFF !important;
    min-height: 54px !important;
    border-radius: 0 !important;
    color: #31A9F3 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.87px !important;
    line-height: 24px !important;
    text-align: center !important;
}
.continue-btn:hover{
    width: 100% !important;
    border: 2px solid #31A9F3 !important;
    background-color: #31A9F3 !important;
    min-height: 54px !important;
    border-radius: 0 !important;
    color: #FFFFFF !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.87px !important;
    line-height: 24px !important;
    text-align: center !important;
}
.continue-btn:focus{
    width: 100% !important;
    border: 2px solid #31A9F3 !important;
    background-color: #31A9F3 !important;
    min-height: 54px !important;
    border-radius: 0 !important;
    color: #FFFFFF;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.87px !important;
    line-height: 24px !important;
    text-align: center !important;
}
.cartIsEmpty {
    text-align: center;
    padding: 8rem;
    font-family: inherit;
    font-size: 1.5rem;
    color: #31A9F3;
}
label{
    font-weight: 800;
    margin-right: 2px;
    padding-bottom: 0;
    margin-bottom: 0;
}
._id{
    display: none;
}
.modify_link{
    text-decoration: underline;
    color: #007BFF;
    cursor:pointer
}
.link{
    color: #007BFF
}
.text_decoration_none{
    text-decoration: none;
}
.text_decoration_none:hover{
    text-decoration: none;
}