@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@100&family=Julius+Sans+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,200;0,700;1,100;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@100;300;400;500;600;700;800&display=swap);
@import url(https://site-assets.fontawesome.com/releases/v6.0.0/css/all.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card_page{background-color: #FAFAFA;padding-top: 16px;padding-bottom: 200px;}
.card_page_top ul{width: 480px;max-width: 100%;margin: 0 auto;position: relative;}
.card_page_top ul li span {
  color: #B7B7B7;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.16px;
  line-height: 24px;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}
  .card_page_top ul li  i {
    width: 48px;
    height: 48px;
    background-color: #D8D8D8;
    border-radius: 50%;
    color: #FFF;
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
} 
  .card_page_top ul li{display: inline-block;text-align: center;width: 50%;}

  .card_page_top ul:before {
    content: "";
    border-bottom: 1px solid #31A9F3;
    position: absolute;
    bottom: 24px;
    left: 0;
    right: 0;
    width: 35%;
    margin: 0 auto;
} 
.card_page_top ul li.active i{background-color: #31A9F3;}
.card_page_top ul li.active span{color:#31A9F3;}
.card_page .left tbody tr {
  box-shadow: 0 7px 19px 0 rgb(0 0 0 / 9%);
  background-color: #FFF;
} 
.card_page .left table {
  border-collapse: separate;width: 100%;
  border-spacing: 0 0.5em;width: 100%;
}
.card_page .left table tr th{color: #000000;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.16px;
  line-height: 24px;}
  .card_page .left table tr td{color: #000000;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 24px;}
    .card_page .left table tr td, .card_page .left table tr th{padding: 24px;}
.card_page .container-fluid {
  max-width: 1300px;
  width: 100%;
} 

.counter_input {
  height: 41px;
  width: 97px;
  border: 1px solid #E7E7E7;
  position: relative;
  background-color: #FFFFFF;
  padding-left: 28px;
  margin: 0;
  padding-right: 28px;
}
.counter_input button {
  width: 28px;
  position: absolute;
  bottom: 0;
  background-color: #E8E8E8;
  top: 0;
}
.counter_input .input_box {
  width: 39px;
  height: 39px;
  padding: 0 2px;
  text-align: center;
}
.counter_input .btn_minus{left: 0px;}
.counter_input .btn_plus{right: 0px;}
.card_page .left  .link{color: #31A9F3;
  font-size: 12px;
  font-weight: 500;text-decoration: underline;
  letter-spacing: 0.87px;
  line-height: 24px;}

  .card_page .right_card {
    width: 100%;
    background-color: #FFF;
    margin-top: 88px;
    border: 1px solid #31A9F3;
    min-height: 527px;
} 
.card_page .right_card h4 {
  background-color: #31A9F3;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1.79px;
  line-height: 24px;
  min-height: 112px;
  display: inline-flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
} 
.right_card_in {
  padding: 65px 70px;
} 
.right_card_in ul li {
  opacity: 0.72;
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.34px;
  margin-bottom: 50px;
  line-height: 24px;
} 
.right_card_in ul li.last{ color: #31A9F3; 
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.79px;
  line-height: 24px;}
  .right_card_in ul li span {
    min-width: 170px;
    display: inline-block;
} 
.right_card .btn-primary {
  width: 100%;
  border: 2px solid #31A9F3;
  background-color: #FFFFFF;
  min-height: 54px;
  border-radius: 0;
  color: #31A9F3;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.87px;
  line-height: 24px;
  text-align: center;
} 
  iframe {
    display: none;
} 
.imgsrc {
  display: none;
}
.stopUserClicks{
  pointer-events: none;
}
/* @import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

body {
  background-color: #f6f6f6 !important;
  font-family: "Archivo", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

label.custom-label {
  font-weight: bold;
  font-size: 13px;
  display: inline-block;
  width: 100%;
  font-family: Archivo;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
}

.custom-width {
  opacity: 0.58;
  color: #000000;
}

.custom-image-area .custom_radio {
  opacity: 0.58;
  color: #000000;
}

.custom-image-area {
  margin-top: 20px;
}

.custom-no {
  width: 100%;
  border: 1px solid #31a9f3;
  text-align: center;
  height: 49px;
  border-radius: 0;
}

.custom-no.warning {
  border-color: #ff0000;
}

.custom-no.warning:focus,
.custom-no:focus {
  outline: 0;
  outline-width: 0;
  box-shadow: none;
}

.custom-dimention {
  border-top: #dcdcdc 2px solid;
  padding: 20px 0;
  margin-top: 18px;
}

.dimention-area {
  margin: 10px 35px;
}

.dimention-area .row {
  justify-content: center;
  align-items: center;
}

a.btn.btn-custom {
  border: 1px #cdcdcd solid;
  border-radius: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  min-width: 150px;
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  font-size: 13px;
}

.custom_radio input {
  display: none;
}

.lower-thumbnail img {
  /* max-width: 130px; */
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  display: flex;
}

.borderThumnailActive {
  border: 1px solid #31a9f3 !important;
}

.thumbnail1 img {
  object-fit: cover;
}

.thumbnail2 img {
  object-fit: cover;
}

.thumbnail,
.thumbnail3 img {
  object-fit: cover;
}

.thumbnail-div {
  display: inline-block;
  vertical-align: top;
  height: 90px;
  width: auto;
  margin: 0;
  cursor: pointer;
  border: 1px solid transparent;
  margin: 0 11px 0 0;
  position: relative;
}

.thumbnail-div img {
  height: 100%;
  background-color: transparent;
  padding: 2px;
  max-width: 150px;
  object-fit: contain;
}

.borderThumnail,
.borderThumnail1,
.borderThumnail2,
.borderThumnail3 {
  border: 1px solid #31a9f3;
}

​​​​​​ .thumbnail3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom_radio span {
  float: left;
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 50%;
  left: 0;
  top: 0px;
  border: 1px #727272 solid;
}

.custom-radiobox {
  display: inline-block;
  text-align: center;
  margin-top: 20px;
}

.custom-radiobox.custom-radiobox-border.custom-radiobox-item {
  text-align: left;
}

.custom_radio input:checked+span:after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #727272;
  position: absolute;
  border-radius: 50%;
  top: 2px;
  left: 2px;
}

a.btn.btn-custom img {
  width: 20px;
  height: 25px;
  margin-left: 15px;
}

.custom_radio {
  float: left;
  position: relative;
  padding-left: 29px;
  font-size: 13px;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  letter-spacing: normal;
  color: #515151;
  margin-top: 35px;
  text-align: left;
}

.white-box .accordion .card .card-body {
  padding-left: 0;
  padding-right: 0;
}

.white-box {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 7px 19px 0 rgb(0 0 0 / 9%);
  font-family: Archivo;
  font-size: 14px;
  font-weight: bolder;
  letter-spacing: 1.25px;
  line-height: 24px;
  color: #000000;
  position: relative;
  z-index: 1;
}

.App {
  margin-top: 50px;
}

/* .container-fluid {
  padding: 0 6px;
} */

.cropper-row {
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.dimention-area .cropper-button {
  border: 1px solid #31a9f3;
  text-align: center;
  height: 49px;
  border-radius: 0;
  background: transparent;
  color: #31a9f3;
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 16px;
  min-width: 90px;
  line-height: 37px;
}

.select-media .dropdown-menu.show {
  margin-bottom: 15px !important;
}

.dimention-area .cropper-button:hover,
.dimention-area .cropper-button:focus,
.dimention-area .cropper-button:not(:disabled):not(.disabled):active,
.dimention-area .cropper-button:not(:disabled):not(.disabled).active {
  border: 1px solid #31a9f3;
  background: #31a9f3;
  box-shadow: none;
  color: #ffffff;
}

.white-box .accordion {
  border: 0;
  border-radius: 0;
}

.white-box .accordion .card {
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-bottom: 1px #dcdcdc solid !important;
}

.white-box .accordion .card .card-header {
  padding: 1.55rem 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.white-box .accordion .card:last-child .card-header {
  border: 0 !important;
}

.white-box .accordion .card-header:last-child {
  border: 0 !important;
}

.white-box .accordion .card:last-child {
  border: 0 !important;
}

.card-header {
  position: relative;
}

.card-header:before {
  display: inline-block;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 8px;
  border-bottom: 1px solid currentColor;
  border-right: 1px solid currentColor;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: 0;
  position: absolute;
}

.preview-area {
  text-align: center;
  margin: auto;
  width: 90%;
}

.test-area {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 100%;
}

.custom-radiobox .custom_radio {
  margin: 7px 15px;
}

.accordion>.card {
  overflow: inherit !important;
}

.custom-select-area .btn-primary {
  background-color: #fff !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 13px;
  min-width: 100%;
  padding: 9.5px 10px;
  text-align: left;
  position: relative;
  border-radius: 0 !important;
  letter-spacing: 1.25px;
}

.mattingInput input,
.mattingInput .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 13px;
  letter-spacing: 1.25px;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.custom-select-area .drop-arrow-icon>.dropdown-menu {
  border: 0 !important;
  border-left: 1px solid #e7e7e7 !important;
  border-right: 1px solid #e7e7e7 !important;
}

.custom-select-area .drop-arrow-icon .dropdown-menu .dropdown-menu {
  border: 0 !important;
}

.custom-select-area .drop-arrow-icon .dropdown-menu .moulding-options {
  border: 0 !important;
}

.custom-select-area .drop-arrow-icon .dropdown-menu .dropdown-toggle.btn {
  border: 0;
  border-bottom: 1px solid #e7e7e7 !important;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.92px;
  line-height: 23px;
}

.custom-select-area .drop-arrow-icon .dropdown-menu .dropdown-toggle.btn:hover {
  background-color: #f5f5f5;
}

.MouldingButton {
  margin-top: 20px !important;
}

.MouldingButton .dropdown-menu.dropdown-menu-right .btn-primary {
  border: 0;
  border-bottom: 1px solid #cccccc;
}

.custom-select-area .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.9rem 0.8rem;
  clear: both;
  /* font-weight: 400; */
  color: #212529;
  text-align: inherit;
  white-space: break-spaces;
  /* background-color: transparent; */
  border: 0;
  font-size: 12px;
  /* font-size: 12px; */
  font-weight: 500 !important;
  letter-spacing: 0.92px;
  line-height: normal;
  border-bottom: 1px #dcdcdc solid;
}

.offset .dropdown-menu .dropdown-item {
  padding-left: 1.8rem !important;
}

.drop-arrow-icon .dropdown-toggle:hover {
  border: 1px solid #40a9ff !important;
}

.drop-arrow-icon .drop-arrow-icon .dropdown-toggle:hover {
  border: 0 !important;
  border-bottom: 1px solid #e7e7e7 !important;
  /* background-color: transparent !important; */
}

.rc-virtual-list .ant-select-item {
  padding: 0.9rem 0.8rem !important;
  color: #212529;
  font-size: 12px;
  font-weight: 500 !important;
  letter-spacing: 0.92px;
  line-height: normal;
  border-bottom: 1px #dcdcdc solid;
}

.inner-dropdown {
  max-width: 200px;
}

.position-unset .dropdown-menu-right {
  position: unset !important;
  -webkit-transform: none !important;
          transform: none !important;
  width: 100% !important;
}

.custom-select-area .dropdown-menu {
  border: 1px solid #e8e8e8;
  background-color: #ffffff;
  box-shadow: 0 2px 14px 0 rgba(188, 188, 188, 0.5);
  left: -2px !important;
  border-radius: 0;
  padding: 0;
}

.custom-select-area .dropdown-toggle::after {
  display: inline-block;
  content: "";
  height: 7px;
  width: 7px;
  margin-right: 8px;
  border-bottom: 1px solid #595959;
  border-right: 1px solid #595959;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  right: 10px;
  position: absolute;
  margin: 0;
  border-top: 0;
  border-left: 0;
  top: 15px;
}

.test-area.text-left {
  text-align: left;
}

.test-custom {
  margin-top: -22px !important;
}

.label-cust em {
  /* display: block; */
  letter-spacing: 0.77px;
  line-height: 15px;
  font-size: 10px;
  text-align: center;
  margin-top: 15px;
  opacity: 0.58;
}

h2.title {
  font-weight: bold;
  font-size: 15px;
  opacity: 0.58;
  color: #000000;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 18px;
  margin: 0;
}

.custom-radiobox.custom-radiobox-border {
  width: 100%;
}

.form-control[type="number"]::-webkit-inner-spin-button,
.form-control[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.row-panel {
  max-width: 85%;
  margin: 0 auto;
}

.row-panel .col-sm-3 {
  padding-left: 10px;
  padding-right: 10px;
}

.row-panel .form-label {
  opacity: 0.72;
  color: #000000;
  font-family: Archivo;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.85px;
  line-height: normal;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  width: 100%;
}

.row-panel .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cccbcb;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
}

.custom-radiobox .label-cust .custom_radio {
  margin: 0;
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
}

.custom-radiobox .label-cust {
  margin: 20px 0;
}

.custom-radiobox .label-cust.label-cust-top {
  margin-top: 0;
}

.form-group.form-add-image {
  position: relative;
  width: 152px;
  height: 55px;
}

.form-group.form-add-image input.form-control {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 50px;
}

.form-group.form-add-image button.btn.btn-danger.btn-block {
  border: 2px solid #31a9f3;
  background: transparent;
  color: #31a9f3;
  height: 55px;
  font-size: 11px;
  border-radius: 0;
  font-weight: bold;
}

.form-group.multi-preview.preview-list {
  display: inline-block;
}

.form-group.multi-preview.preview-list img {
  width: 70px;
  height: 85px;
  object-fit: cover;
  margin-right: 10px;
}

.form-group.form-add-image {
  position: relative;
  width: 152px;
  height: 55px;
  display: inline-block;
}

.col-white-box {
  background: #fff;
  font-family: Archivo;
}

.col-lg-3.col-padding {
  padding-right: 0;
  display: flex;
}

.col-white-box {
  background: #fff;
  padding: 20px 30px;
}

.col-white-box h2 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 24px;
  text-align: center;
}

.price-area h2 {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.25px;
  line-height: 24px;
  text-align: center;
  font-family: Archivo;
}

.price-area {
  border-top: 1px solid rgb(151, 151, 151, 0.5);
  clear: both;
  padding-top: 25px;
  font-family: Archivo;
}

.print-label-size ul li {
  list-style: none;
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  letter-spacing: 1.28px;
  line-height: 24px;
  width: 100%;
  float: left;
  margin-bottom: 10px;
  font-weight: 600;
}

.print-label-size ul li label {
  color: #000;
  font-weight: 600;
  float: left;
  font-size: 13px;
  padding-right: 10px;
  font-family: Archivo;
}

.print-label-size ul li span {
  color: rgba(0, 0, 0, 0.5);
}

.quantity-container {
  text-align: center;
  padding: 0 20px;
}

.quantity-container .row {
  justify-content: center;
  align-items: center;
  align-self: center;
}

.quantity-container .row .form-label {
  margin-bottom: 0;
  color: #000000;
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
}

.quantity-container .react-numeric-input b {
  border: none;
  background: transparent !important;
}

.qty-contain .form-row {
  margin: 0;
  justify-content: center;
}

.qty-contain .form-control {
  height: 70px;
  background: #f6f6f6;
  border: 0 !important;
}

.qty-contain .input-group {
  height: 70px;
  background: #f6f6f6;
  border: 0 !important;
}

.qty-contain .input-group-text {
  background-color: #f6f6f6;
  border: 0;
}

label.label-print.form-label {
  opacity: 0.42;
  color: #000000;

  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.77px;
  line-height: 24px;
  display: flow-root;
}

.qty-contain .col-3 {
  margin: 0 10px;
}

.qty-contain {
  margin-top: 40px;
  padding: 0 20px;
}

.btn-primary-cart {
  width: 100%;
}

.submit-btn {
  box-sizing: border-box;
  height: 54px;
  width: 164px;
  margin-top: 20px;
  margin-left: 70px;
}

button.btn-primary.btn-primary-cart {
  background-color: #31a9f3;
  height: 54px;
  border-radius: 0;
  border: 2px solid #31a9f3;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.87px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

button.btn-primary-cart.btn.btn-secondary {
  background-color: transparent;
  height: 54px;
  border-radius: 0;
  border: 2px solid #31a9f3;
  font-size: 12px;
  color: #31a9f3;
  font-weight: 500;
  letter-spacing: 0.87px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  white-space: inherit;
}

button.btn-primary-cart.btn.btn-secondary:hover,
button.btn-primary-cart.btn.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #31a9f3;
  height: 54px;
  border-radius: 0;
  border: 2px solid #31a9f3;
  font-weight: 500;
  letter-spacing: 0.87px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.farm-mount-area .custom-radiobox .label-cust {
  margin: 10px 0;
  float: left;
  width: 100%;
}

.area-left {
  text-align: left;
}

.print-tutorial button {
  margin-bottom: 60px;
}

.print-tutorial button.btn-primary.btn-primary-cart {
  background-color: #31a9f3;
  height: 50px;
  border-radius: 0;
  border: 2px solid #31a9f3;
  font-size: 12px;
  font-weight: 600;
  font-family: Archivo;
  letter-spacing: 0.87px;
  line-height: 35px;
  display: flex;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  max-width: 186px;
}

.print-tutorial button.btn-primary.btn-primary-cart :hover {
  background-color: #31a9f3;
}

.print-tutorial button.btn-primary.btn-primary-cart img {
  width: 32px;
  height: 32px;
  margin: 8px 10px 0px 0;
}

.sidebar_tutorial {
  position: relative;
  height: 100%;
  margin-bottom: 20px;
}

.print-tutorial {
  top: 20px;
  position: relative;
}

.quantity-container .react-numeric-input .form-control {
  border: 1px solid #e7e7e7;
}

/* .cropper-modal {
  background: transparent !important;
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.heading-wrapper h1 {
  font-family: "Julius Sans One";
  font-size: 35px;
  letter-spacing: 3.92px;
  line-height: 45px;
  margin-bottom: 10px;
}

.dragger div.ant-upload {
  border: 1px dashed #929292;
  border-radius: 1px;
  max-width: 450px;
  margin: auto;
  padding: 20px;
}

.dragger .anticon-cloud-upload {
  margin-bottom: 20px;
}

.dragger .ant-btn {
  margin-top: 20px;
}

.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}

.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}

.files {
  position: relative;
}

.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.color input {
  background-color: #f1f1f1;
}

.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

#menu-toggle:checked+#menu {
  display: block;
}

.uploadButton {
  height: 200px !important;
  width: 400px !important;
}

.border-area {
  border: 1px solid #3584e6;
  opacity: 0.4;
}

.iconPositionStyle {
  margin-bottom: 0;
  z-index: 1000000;
  position: absolute;
  right: 4px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.thumbnail.lower-thumbnail {
  position: relative;
}

.bg-color {
  background-color: #f6f6f6;
  height: 100%;
  width: 100%;
}

.makeorder {
  padding: 0 0 0 70px;
}

.select-section {
  flex-basis: 472px;
  padding: 130px 0;
  margin-right: 60px;
}

.text_area {
  min-height: calc(100vh - 124px);
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.information {
  display: block;
  padding-inline: 20px;
}

.cart {
  display: block;
  padding-inline: 20px;
}

.react-numeric-input {
  position: relative;
  display: block;
  width: 100px;
  margin-top: 10px;
}

.Quantity {
  margin-top: 20px;
}

.price {
  margin-right: 28px !important;
}

.numeric b:last-child {
  top: 9px !important;
  bottom: 6px !important;
  border-radius: 0px !important;
  height: 19px !important;
  border: 0 !important;
  border-right: 1px solid #e7e7e7 !important;
}

.numeric b:nth-child(2) {
  top: 9px !important;
  bottom: 6px !important;
  border-radius: 0px !important;
  height: 19px !important;
  border: 0 !important;
  border-left: 1px solid #e7e7e7 !important;
}

.pricingRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000 !important;
}

.pricingText {
  flex-basis: 79px;
  text-align: right;
  margin-right: 15px;
  font-family: Archivo;
  font-weight: 600 !important;
}

.pricinginput {
  flex: 1 1;
}

.pricinginput span {
  margin-top: 0;
}

.perfect-prints-guara {
  color: #404040;
  font-family: "Crimson Text";
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 2.29px;
  line-height: 35px;
  margin-bottom: 30px;
}

.cloud-image {
  margin: auto;
  max-width: 70px;
}

.drag-and-drop-to-upl {
  margin-top: 10px;
  color: #949494;
  font-family: Archivo;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
}

.addnewfile {
  box-sizing: border-box;
  height: 54px;
  width: 125px;
  border: 2px solid #31a9f3;
  background-color: #ffffff;
}

.add-new-file {
  margin-top: 11px;
  color: #31a9f3;
  font-family: Archivo;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: -0.2px;

  text-align: center;
}

.homeimage {
  width: 100%;
  height: calc(100% - 250px);
}

.thumbnail-area {
  margin: 0;
  overflow: hidden;
  display: inline-block;
}

.upper,
.upper1 {
  width: 22px;
  margin-left: 5%;
  -webkit-margin-left: 6%;
  position: absolute;
  margin-top: 1.4%;
  height: 28px;
}

.image-background1 {
  height: 563px;
  width: 90%;
  margin: 28px auto 48px auto;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 12%) 0px 4px 5px,
    rgb(0 0 0 / 14%) 0px 2px 4px;
}

.image-background2 {
  width: -moz-fit-content !important;
  /* width: fit-content !important; */
  margin: 5% 10%;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 12%) 0px 4px 5px,
    rgb(0 0 0 / 14%) 0px 2px 4px;
  position: relative;
}

.frameOption {
  width: 200;
  margin-left: 30;
}

.mouldingtype2 {
  margin-left: 30;
}

.moulding-type {
  margin-left: 30;
}

.floating {
  margin-left: 30;
}

.selectoption {
  width: 200;
  margin-left: 30;
}

.card-header:before {
  display: none;
  content: "";
  height: 8px;
  width: 8px;
  margin-right: 8px;
  border-bottom: 1px solid currentColor;
  border-right: 1px solid currentColor;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  right: 0;
  position: absolute;
}

.text-right {
  float: right;
}

.custom-border-area .continue-button {
  color: #31a9f3;
  font-family: Archivo;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0.87px;
  line-height: 24px !important;
  text-align: center;
  border-radius: 0;
  padding: 10px 22px !important;
  border-color: #31a9f3;
}

.custom-border-area .continue-button :hover {
  color: #31a9f3;
  border: 1px solid #31a9f3;
  background: transparent;
}

.ant-space-item:nth-child(2) {
  margin: 10px 0 8px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 30px;
}

.browse-button {
  height: 55px;
  padding: 0 38px;
  font-family: Archivo;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.71px;
  line-height: 24px;
}

.header-section {
  padding: 15px 0px !important;
  margin-bottom: 13px;
  padding-bottom: 25px !important;
}

.login-button {
  border: 2px solid #31a9f3 !important;
  padding: 14px 40px;
  color: #31a9f3;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.71px;
  line-height: 24px;
}

.login-button-a {
  color: #31a9f3;
  text-decoration: none;
}

.login-button-a:hover {
  color: #31a9f3;
  text-decoration: none;
}

.rotate-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  height: 49px !important;
  padding: 0 0 0 19px !important;
}

.bg-white {
  padding: 0 0 50px;
}

.browse-img {
  margin-left: 30px;
}

.select-media .btn-primary {
  min-width: 100%;
}

.upper-division {
  width: 100%;
  height: 0px;
}

.select-media .dropdown-menu.show.dropdown-menu-right {
  width: 100%;
}

.select-media a.dropdown-item {
  padding: 10px 5 !important;
}

.arrow-image {
  height: 12px;
  margin-top: 3px;
}

.arrow-image-dropdown {
  height: 12px;
  position: absolute;
  z-index: 1;
  right: 12px;
  top: 14px;
}

.toast-body {
  position: relative;
}

.toast {
  border: 1px solid #31a9f3 !important;
  box-shadow: 0 0.25rem 0.75rem rgb(49 169 243 / 19%) !important;
}

.toast-body .gif-img {
  flex-basis: 50px;
  max-width: 50px;
}

.headerLeft {
  max-width: 300px;
}

.toaster-h {
  font-size: 12px;
  text-align: left;
}

.cdjcs {
  position: absolute;
  right: 18px;
  top: 0px;
  font-size: 20px;
  color: #ccc;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  cursor: pointer;
}

.Cropper.cropper-toaster {
  top: 105px;
}

.toaster-h span {
  font-size: 14px;
  font-weight: bold;
  display: block;
}

.Toastify__toast-theme--light {
  border: 1px solid #20820f !important;
  box-shadow: -1 0.25rem 0.75rem #31522b !important;
}

.homeimagefull {
  background: url(/static/media/treicera1.eb267417.png);
  background-size: contain;
  min-height: calc(100vh - 104px);
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  background-position: right;
}

.uploader-div {
  z-index: 999;
  position: relative;
}

.empty-toaster {
  width: 90%;
}

.empty-toaster .toast {
  border: 1px solid #ff0000 !important;
  box-shadow: 0 0.25rem 0.75rem #ff00002f !important;
}

.cart-control-btn button.btn-primary-cart.btn.btn-secondary,
.cart-control-btn button.btn-primary.btn-primary-cart {
  height: 60px !important;
}

button.btn-primary-cart.btn.btn-secondary,
button.btn-primary.btn-primary-cart {
  height: 54px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  line-height: 16px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.file-error {
  color: #ff0000;
  font-family: Archivo;
  font-size: 9.5px;
  font-weight: 600;
  letter-spacing: 0.77px;
  line-height: 11px;
}

.uploading-file {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 38px;
}

.loader-gif {
  height: 158px;
  width: 151px;
  margin: auto;
}

.print-tutorial-text {
  margin: 8px 0;
}

.custom-select-area .dropdown-toggle::after {
  display: none;
}

.framing-and-mounting-button-text {
  height: 70px !important;
}

.checkout-btn {
  height: 54px !important;
  width: auto;
  background-color: #31a9f3 !important;
  border: 2px solid #31a9f3 !important;
  font-family: Archivo;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.87px;
  text-align: center;
  border-radius: 0px !important;
}

.viewcart-btn {
  height: 54px !important;
  width: auto;
  background-color: white !important;
  border: 2px solid #31a9f3 !important;
  font-family: Archivo;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.87px;
  text-align: center;
  border-radius: 0px !important;
  color: #31a9f3 !important;
}

.background-first,
.background-second {
  position: absolute;
  z-index: 1;
  /* margin-left: 36%;
  margin-top: -60%; */
  /* box-shadow: 4px 4px 10px -2px #9a9a9a; */
}

.background-deafult {
  height: 435px;
  width: 90%;
  margin: 0;
  box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 25%) !important;
}

.frameBlock {
  /* height: 500px; */
  width: 100%;
  margin: 30px auto;
}

.frameBlock img {
  height: 411px;
  width: 100%;
}

.deafult {
  height: 400px;
  width: 80%;
}

.arrow-image-center {
  margin-left: 9px;
}

.slick-prev:before {
  content: "";
  width: 14px;
  height: 14px;
  border-top: 2px solid #333;
  border-left: 2px solid #333;
  display: block;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.slick-next:before {
  content: "";
  width: 14px;
  height: 14px;
  border-top: 2px solid #333;
  border-left: 2px solid #333;
  display: block;
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
}

.slick-slide {
  /* width: auto !important;
  height: auto !important; */
  margin-bottom: 0px;
}

.sliderPart {
  display: flex;
  align-items: center;
}

.sliderPart .flex-initial {
  max-width: 130px;
  display: inline-block;
  flex: auto;
}

.sliderWrapper {
  max-width: calc(100% - 156px);
  display: inline-block;
  padding: 0 27px;
  width: 100%;
}

.slick-slide {
  /* width: auto !important; */
}

.slick-prev {
  margin-left: 10px;
}

.slick-track {
  margin: 0px;
}

.file-format-text {
  color: #949494;
  margin-top: 15px;
}

.file-format-waring {
  color: red;
}

.continue-button-border {
  color: #31a9f3;
  font-family: Archivo;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0.87px;
  line-height: 20px !important;
  text-align: center;
  border-radius: 0;
  padding: 9px 22px !important;
  border-color: #31a9f3;
  margin: 22px auto auto auto;
  display: flex;
}

.border-text {
  letter-spacing: 0.77px !important;
  line-height: 15px !important;
  font-size: 10px !important;
  opacity: 0.58 !important;
  text-align: left !important;
  text-align: initial !important;
}

.card-header {
  border-bottom: 0px !important;
}

.blueColor {
  color: #31a9f3;
}

.dimension-display {
  display: flex;
}

.frame-dimension-display {
  margin-left: 30px;
  display: flex;
  position: relative;
  margin-bottom: 50px;
}

.horizontal-arrow-outside {
  display: flex;
  height: 200px !important;
  width: 32px;
}

.height {
  padding-top: 86px;
  margin: -10px;
  margin-top: 14px;
}

.vertical-arrow-outside {
  width: 153px;
  height: 20px;
  margin-left: 47px;
}

.width {
  margin-left: 105px;
  margin-top: -3px;
}

.horizontal-arrow-inside {
  position: absolute;
  margin-left: 10px;
  height: 184px;
  width: 42px;
}

.vertical-arrow-inside {
  margin-top: 22px !important;
}

.height-inside {
  position: absolute;
  left: 26px;
  bottom: 0;
  top: 0;
  height: 24px;
  margin: auto;
  font-size: 12px;
  font-weight: bold;
}

.width-inside {
  position: absolute;
  left: 0;
  bottom: 23px;
  right: 0;
  text-align: center;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
}

.height-outside {
  position: absolute;
  left: -47px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
}

.width-outside {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -56px;
  font-size: 12px;
  font-weight: bold;
}

.frame-options {
  border-bottom: 1px solid #dcdcdc;
}

.moulding-options {
  border-bottom: 1px solid #e7e7e7 !important;
  border-top: 0px !important;
}

.dropdown-menu-align-right {
  margin-top: 20px;
}

.ant-select-dropdown {
  padding: 0px;
}

.collapse .card-body {
  margin-top: -20px;
}

.radio-inline {
  display: flex;
  justify-content: space-between;
}

.radio-inline .custom_radio {
  margin: 0;
}

.ant-slider-rail {
  height: 3px;
  background-color: #efefef;
  border-radius: 0;
  border: 0px;
}

.ant-slider-track {
  height: 4px;
  background-color: transparent;
}

.ant-slider-handle {
  width: 24px;
  height: 24px;
  margin-top: -9px;
  border: solid 2px #31a9f3;
  background: #31a9f3;
  box-shadow: 0 2px 4px rgb(132 132 132 / 50%);
}

.ant-slider:hover .ant-slider-track {
  background-color: transparent;
  border: 0px;
}

.SliderRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ant-slider {
  flex: 1 1;
}

.SliderRow>div:first-child {
  flex-basis: 20px;
  font-size: 19px;
  color: #575757;
}

.SliderRow>div:last-child {
  flex-basis: 20px;
  text-align: right;
  font-size: 19px;
  color: #575757;
}

.preview-area {
  position: relative;
}

.preview-area .cropper-toaster.Cropper {
  top: calc(50% - 30px);
}

.papertype-discription {
  text-align: left;
  font-size: 13px;
  line-height: 15px;
  color: #262626;
  margin: 30px auto 0;
  letter-spacing: 1.1px;
}

.slide-border-line {
  width: 100%;
  text-align: center;
  font-size: 11px;
  letter-spacing: 0.85px;
}

.upper-division .upper-frame-box,
.upper-division .upper-matting-box,
.upper-division .upper-border-box {
  display: flex;
}

.upper-division .upper-border-box img {
  width: 100% !important;
}

.frame_dimension_display_box .frame-preview-black-small {
  box-shadow: 0 0.5rem 2rem rgb(0 0 0 / 25%) !important;
}

.frame-preview-black-small {
  border: 5px solid black;
  /* box-shadow: rgb(0 0 0) 0px 1px 10px, rgb(0 0 0) 0px 4px 5px, rgb(0 0 0) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-black-medium {
  border: 10px solid black;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 80%) 1px 5px 51px, rgb(0 0 0 / 14%) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-black-large {
  border: 15px solid black;
  /* box-shadow: rgb(0 0 0 / 50%) 0px 8px 30px, rgb(0 0 0 / 50%) 0px 8px 30px, rgb(0 0 0 / 50%) 0px 8px 30px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-black-standard {
  border: 10px solid black;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 80%) 1px 5px 51px, rgb(0 0 0 / 14%) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-white-small {
  border: 5px solid white;
  /* box-shadow: rgb(0 0 0) 0px 1px 10px, rgb(0 0 0) 0px 4px 5px, rgb(0 0 0) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-white-medium {
  border: 10px solid white;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 80%) 1px 5px 51px, rgb(0 0 0 / 14%) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-white-large {
  border: 15px solid white;
  /* box-shadow: rgb(0 0 0 / 50%) 0px 8px 30px, rgb(0 0 0 / 50%) 0px 8px 30px, rgb(0 0 0 / 50%) 0px 8px 30px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-white-standard {
  border: 10px solid white;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 80%) 1px 5px 51px, rgb(0 0 0 / 14%) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-maple-small {
  border: 5px solid #d2ae8c;
  /* box-shadow: rgb(0 0 0) 0px 1px 10px, rgb(0 0 0) 0px 4px 5px, rgb(0 0 0) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-maple-medium {
  border: 10px solid #d2ae8c;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 80%) 1px 5px 51px, rgb(0 0 0 / 14%) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-maple-large {
  border: 15px solid #d2ae8c;
  /* box-shadow: rgb(0 0 0 / 50%) 0px 8px 30px, rgb(0 0 0 / 50%) 0px 8px 30px, rgb(0 0 0 / 50%) 0px 8px 30px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-maple-standard {
  /* border: 10px solid #c73d3d; */
  border: 10px solid #d2ae8c;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 80%) 1px 5px 51px,  rgb(0 0 0 / 14%) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-grey-small {
  border: 5px solid gray;
  /* box-shadow: rgb(0 0 0) 0px 1px 10px, rgb(0 0 0) 0px 4px 5px,  rgb(0 0 0) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-grey-medium {
  border: 10px solid gray;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 80%) 1px 5px 51px, rgb(0 0 0 / 14%) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-grey-large {
  border: 15px solid gray;
  /* box-shadow: rgb(0 0 0 / 50%) 0px 8px 30px, rgb(0 0 0 / 50%) 0px 8px 30px, rgb(0 0 0 / 50%) 0px 8px 30px; */
  box-shadow: 0 0 9px #000;
}

.frame-preview-grey-standard {
  border: 10px solid gray;
  /* box-shadow: rgb(0 0 0 / 20%) 0px 1px 10px, rgb(0 0 0 / 80%) 1px 5px 51px, rgb(0 0 0 / 14%) 0px 2px 4px; */
  box-shadow: 0 0 9px #000;
}

/* Css changes on 23 aug 2021 */

.select-frame-option .ant-select-selection-placeholder {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 35px;
  color: #000000;
  font-family: "Archivo", sans-serif;
}

.ant-select-item.ant-select-item-option.frame-options {
  padding: 10px;
}

.ant-select-item.ant-select-item-option.frame-options .ant-select-item-option-content {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.92px;
  line-height: 24px;
}

.ant-select-selection-item {
  font-weight: bold;
  font-size: 13px;
}

.dropdown-menu.show.dropdown-menu-right {
  position: relative !important;
  right: auto !important;
  bottom: auto !important;
  -webkit-transform: none !important;
          transform: none !important;
  width: 100% !important;
  left: 0px !important;
  box-shadow: none !important;
}

.drop-arrow-icon .dropdown-toggle {
  background-image: url(/static/media/arrow-down.bbe1a337.png) !important;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center right 14px;
  /* border: 1px solid #d9d9d9 !important; */
}

.drop-arrow-icon.show>.dropdown-toggle {
  background-image: url(/static/media/arrow-up.affc0507.png) !important;
}

.imageback1 .reactEasyCrop_Container {
  position: relative;
}

.imageback1 .reactEasyCrop_Container img {
  position: unset;
}

.button-disabled {
  border-color: black;
  color: black;
  width: 100% !important;
  height: 60px !important;
  font-size: 11px !important;
  font-weight: 800 !important;
}

/* .button-disabled2 {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  height: 54px !important;
  width: auto;
  color: white !important;
} */

/* .btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: transparent !important;
  border-radius: 0 !important;
  border: 2px solid grey !important;
  font-size: 12px !important;
  color: grey !important;
  font-weight: 500 !important;
  letter-spacing: 0.87px !important;
  line-height: normal !important;
  text-align: center !important;
  text-transform: uppercase !important;
  white-space: inherit !important;
  font-size: 11px !important;
  font-weight: 800 !important;
} */

/* .btn-primary.disabled,
.btn-primary:disabled {
  height: 54px !important;
  width: 164px;
  background-color: grey !important;
  border: 2px solid grey !important;
  font-family: Archivo;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 0px !important;
} */

.cropper-hidden {
  opacity: 0;
  max-width: 100%;
  width: auto;
  height: 100%;
  object-fit: cover;
}

.static-block {
  min-height: 563px;
  margin: 30px 0;
  display: flex;
  align-items: center;

  /* max-height: 470px; */
}

.ant-badge-count {
  top: -1px !important;
  right: 20px !important;
}

.cropper-toaster {
  top: 120px;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 9998;
}

.cropper-warning {
  top: calc(40% - 30px);
  text-align: center;
  left: 0;
  right: 0;
  z-index: 9999;
  font-weight: 600;
  color: #b01115;
}

.cropper-warning button {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
}

.cropper-warning .toast {
  border: 1px solid #b01115 !important;
}

.toast {
  margin: auto !important;
}

.cartToasterContainer {
  position: relative;
}

.cartToaster {
  left: 0;
  right: 0;
  z-index: 11;
  margin: 0 !important;
  top: -46px;
  width: 100%;
  position: absolute;
}

.cartToaster .toast {
  background-color: green;
  color: white;
}

.cropper-toasterc {
  top: 108px;
  text-align: center;
  left: 0;
  right: 0;
}

.emptyCart {
  z-index: 11;
}

.frameOptionSelect {
  background-image: url(/static/media/arrow-down.bbe1a337.png) !important;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  background-position: center right 12px;
}

.frameOptionSelect .ant-select-arrow {
  display: none;
}

.frameOptionSelect .ant-select-selector {
  background-color: transparent !important;
}

.upload-area {
  margin-bottom: 30px;
  /* margin-top:30px; */
}

/* .dropdown-item:hover {
  background-color: transparent !important;
} */

.frame-options:hover {
  background-color: #e9ecef !important;
}

.hoverEffect:hover {
  background-color: #e9ecef !important;
}

.hoverEffect .dropdown-toggle .btn .btn-primary:hover {
  background-color: #e9ecef !important;
}

.custom-select-area .drop-arrow-icon .dropdown-menu .dropdown-toggle.btn:hover {
  background-color: #e9ecef !important;
}

.btn-group {
  padding-top: 20px;
  padding-left: 20px;
}

.btn-group .btn-primary {
  width: auto;
  margin: 0 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.header-section #menu {
  padding-top: 11px;
}

.container-fluid.container-fluid-header {
  padding: 0;
}

.myfiles {
  padding-inline: 20px;
}

.myFiles_link {
  color: #616161;
}

.myFiles_link:hover {
  color: #616161;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (min-width: 350px) and (max-width: 1000px) {
  .thumbnail3 {
    width: 75px !important;
  }
}

@media screen and (min-width: 340px) and (max-width: 350px) {
  .thumbnail3 {
    width: 65px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .box-in-mobile {
    flex-wrap: nowrap !important;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .custom-radiobox .custom_radio {
    margin: 5px 5px;
  }

  .uploader-div {
    padding: 30px 20px !important;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1266px) {
  .row-panel .form-label {
    font-size: 7px !important;
  }
}

@media only screen and (min-width: 350px) and (max-width: 1000px) {
  .print-tutorial button.btn-primary.btn-primary-cart {
    display: none;
  }

  .sliderPart .flex-initial {
    display: flex !important;
    margin: auto !important;
  }

  .thumbnail-area-mobile>div {
    height: 56px !important;
  }

  .print-tutorial {
    display: none;
  }

  .image-transformation {
    margin-top: 30px;
  }

  .thumbnail3 {
    width: 70px !important;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }
}

@media only screen and (min-width: 350px) and (max-width: 366px) {
  .thumbnail-area-mobile>div {
    height: 50px !important;
  }

  .thumbnail3 {
    width: 63px !important;
  }

  .lower-thumbnail img {
    max-width: 73px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 340px) and (max-width: 350px) {
  .thumbnail-area-mobile>div {
    height: 50px !important;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .lower-thumbnail img {
    max-width: 73px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 340px) {
  .thumbnail-area-mobile>div {
    height: 45px !important;
  }

  .print-tutorial {
    display: none;
  }

  .thumbnail-area-mobile {
    margin-top: 25px !important;
  }

  .thumbnail3 {
    width: 57px !important;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .lower-thumbnail img {
    max-width: 73px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 800px) {
  .thumbnail-div {
    margin: 0 8px 0 0;
  }

  .static-block {
    /* min-height: 230px !important; */
    min-height: 0px !important;
    /* max-height: 230px !important; */
  }

  .frameBlock {
    height: auto !important;
  }
}

@media (max-width: 1399px) {
  .select-media .dropdown-menu.show.dropdown-menu-right {
    width: 100% !important;
  }
}

@media (min-width: 1700px) {
  .homeimagefull {
    background-size: cover;
  }
}

@media (min-width: 1200px) {
  .w-full.select-section.grid-upload-left {
    flex: 0 0 40%;
    max-width: 40%;
    padding: 0px 0;
    margin: 0;
  }

  .w-full.hideOnSmall.grid-upload-right {
    flex: 0 0 60%;
    max-width: 60%;
    position: relative;
  }

  .w-full.hideOnSmall.grid-upload-right .homeimage {
    height: calc(100% - 50px);
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .arrow-image-dropdown {
    height: 11px;
    position: absolute;
    z-index: 1;
    right: 8%;
    top: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .w-full.select-section.grid-upload-left {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 0;
    margin: 0;
  }

  .w-full.hideOnSmall.grid-upload-right {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
  }

  .w-full.hideOnSmall.grid-upload-right .homeimage {
    height: calc(100% - 50px);
  }

  .heading-wrapper h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .perfect-prints-guara {
    font-size: 25px;
    margin-bottom: 50px;
    line-height: 45px;
  }
}

@media (max-width: 991px) {
  .homeimagefull {
    display: none;
  }

  .makeorder.flex-orders .heading-wrapper h1 {
    max-width: 80%;
    margin: 0 auto 15px;
  }

  .hideOnSmall {
    display: none !important;
  }

  .grid-upload-left.select-section {
    flex-basis: 100%;
    padding: 35px 0;
    margin-right: 0;
  }

  .makeorder.flex-orders {
    padding: 0;
  }

  .frame_dimension_display_box {
    text-align: center;
  }

  img.banner_img {
    margin-top: -11px;
  }
}

@media (min-width: 300px) and (max-width: 700px) {
  .print-tutorial {
    margin-top: 50px;
  }

  .print-tutorial button.btn-primary.btn-primary-cart {
    max-width: 100%;
    margin-bottom: 50px;
  }

  .sliderPart {
    display: block !important;
  }

  .addnewfile {
    margin-bottom: 10px !important;
    text-align: center !important;
  }

  .sliderWrapper {
    max-width: 100% !important;
    padding: 0 0px;
    display: inline !important;
    display: initial !important;
  }

  .homeimage {
    display: none !important;
  }

  .makeorder {
    padding: 0 0 0 0px;
  }

  .select-section {
    flex-basis: auto !important;
    padding: 0px 0px;
    margin-right: 0px;
  }

  .cart {
    display: inline !important;
  }

  #menu-toggle:checked+#menu {
    display: flex !important;
  }

  .thumbnail-area {
    margin: 0% 3%;
  }

  .browse-img {
    margin-left: 14px;
  }

  .ant-row {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 1200px) {

  button.btn-primary-cart.btn.btn-secondary,
  button.btn-primary.btn-primary-cart {
    height: 60px !important;
    font-size: 11px !important;
    font-weight: 800 !important;
  }

  .radio-inline .custom_radio {
    font-size: 12px;
    padding-left: 20px;
  }
}

@media (min-width: 992px) {
  .uploader-div {
    width: 100%;
  }
}

@media (min-width: 1750px) {
  .select-section {
    flex-basis: 1402px;
    padding: 0;
    margin-right: 0px;
  }

  .heading-wrapper h1 {
    max-width: 500px;
    margin: auto;
  }

  .text_area {
    padding: 0;
  }

  .homeimage {
    height: calc(100% - 20px);
  }
}

@media (max-width: 1200px) {
  .cropper-toaster {
    top: 100px !important;
    text-align: center;
    left: 0;
    right: 0;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 350px) {
  .cropper-toaster .cursor-pointer {
    position: absolute;
    right: 6px;
    background: #fff;
  }

  .cropper-toaster {
    top: 100px;
    text-align: center;
    left: 0;
    right: 0;
  }

  .cropper-toasterc .cursor-pointer {
    position: absolute;
    right: 6px;
    background: #fff;
  }

  .cropper-toasterc {
    z-index: 11;
    top: 97px !important;
  }

  .toast-body {
    padding: 1px !important;
  }

  .upper,
  .upper1 {
    margin-left: 9% !important;
    margin-top: 2% !important;
  }
}

@media only screen and (min-width: 320px) and (max-device-width: 340px) {

  .upper,
  .upper1 {
    margin-left: 10%;
    margin-top: 3%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 341px) and (max-device-width: 355px) {

  .upper,
  .upper1 {
    margin-left: 8%;
    margin-top: 2%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 356px) and (max-device-width: 365px) {

  .upper,
  .upper1 {
    margin-left: 8%;
    margin-top: 2%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 366px) and (max-width: 380px) {

  .upper,
  .upper1 {
    margin-left: 8%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 380px) and (max-device-width: 405px) {

  .upper,
  .upper1 {
    margin-left: 9%;
    margin-top: 2%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 405px) and (max-device-width: 430px) {

  .upper,
  .upper1 {
    margin-left: 7%;
    margin-top: 2%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media (max-width: 767px) {
  .header-section {
    padding: 20px 5px !important;
  }

  .header-section .w-full {
    width: auto;
    margin: 0 !important;
  }

  .headerLeft {
    margin-left: 1px !important;
    max-width: 170px;
  }

  .header-section div#menu {
    display: flex;
  }

  .header-section .cart {
    padding: 0 0 0 15px;
  }

  .login-button {
    padding: 8px 12px;
    font-size: 10px;
    letter-spacing: 0px;
    line-height: 15px;
  }
}

@media (max-width: 480px) {
  .headerLeft {
    max-width: 150px;
  }
}

@media only screen and (min-width: 430px) and (max-device-width: 450px) {

  .upper,
  .upper1 {
    margin-left: 7%;
    margin-top: 2%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-device-width: 450px) and (max-device-width: 570px) {
  .background-second {
    margin-left: 41%;
    margin-top: -43%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-device-width: 570px) and (max-device-width: 615px) {
  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 615px) and (max-device-width: 675px) {

  .upper,
  .upper1 {
    margin-left: 5%;
    margin-top: 1%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 675px) and (max-device-width: 744px) {

  .upper,
  .upper1 {
    margin-left: 4%;
    margin-top: 1%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-device-width: 765px) and (max-device-width: 785px) {

  .upper,
  .upper1 {
    margin-left: 4%;
    margin-top: 1%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 785px) and (max-device-width: 810px) {

  .upper,
  .upper1 {
    margin-left: 4%;
    margin-top: 1%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (min-width: 810px) and (max-device-width: 820px) {

  .upper,
  .upper1 {
    margin-left: 4%;
    margin-top: 1%;
  }

  .lower-thumbnail img {
    max-width: 100px !important;
    height: 100% !important;
  }

  .thumbnail {
    height: 58px;
  }
}

@media only screen and (max-width: 800px) {
  .cropper-toasterc {
    z-index: 11;
    top: 97px !important;
  }

  .price-area h2 {
    margin: 0;
  }

  .form-group {
    margin-bottom: 0 !important;
  }

  .quantity-container {
    padding: 0px 46px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  .qty-contain {
    padding: 0px 46px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }

  .submit-btn {
    box-sizing: border-box;
    height: 54px;
    width: 164px;
    /* margin-top: 20px; */
    /* margin-left: 70px; */
    display: flex;
    margin: 10px auto auto auto;
  }

  /* .slick-next:before {
    display: none !important;
  }

  .slick-prev:before {
    display: none !important;
  } */

  /* .sliderPart {
    overflow-x: hidden !important;
  } */
  .upload-area {
    margin-left: 10px;
    margin-right: 10px;
  }

  .image-background1 {
    height: 230px;
  }

  .papertype-discription {
    font-size: 10px !important;
    line-height: 11px !important;
  }
}

.btn_group {
  width: 100%;
  display: inline-block !important;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}

.btn_group .btn {
  align-content: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.87px;
  height: 54px !important;
  line-height: 24px;
  text-align: center;
  border-radius: 0px;
}

.btn_group .btn:last-child {
  margin-right: 0px;
}

.btn_group .btn-outline-primary {
  border: 2px solid #31a9f3;
  color: #31a9f3;
}

.white-box .accordion .card .card-header .arrow_icons img {
  margin-top: 0;
}

.white-box .accordion .card .card-header {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.btn-groups .btn {
  border: 2px solid #31a9f3;
  min-height: 54px;
  color: #31a9f3;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.87px;
  line-height: 15px;
  background-color: transparent;
  border-radius: 0;
  white-space: normal;
  padding: 5px;
}

.btn.btn_disabled {
  opacity: 0.4;
  pointer-events: none;
}

.left_white_box>.container,
.center_white_box>.container,
.print_sidebar>.container {
  padding-right: 0px;
  padding-left: 0px;
}

.frame_dimension_display_box {
  text-align: center;
}

.w100p {
  width: 100%;
}

.toast-body {
  padding: 12px 17px 12px 21px;
}

.custom_toaster {
  position: relative;
  padding-left: 56px;
  min-height: 40px;
}

.custom_toaster .close_icon {
  position: absolute;
  right: 0;
  top: 0;
}

.custom_toaster>.gif-img {
  position: absolute;
  left: 4px;
  top: calc(50% - 19px);
}

.custom_toaster h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.custom_toaster>span {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000;
}

@media (max-width: 1699px) {
  .homeimagefull {
    background-position: top right;
  }
}

@media (max-width: 1100px) {
  .inner-dropdown {
    max-width: 100%;
  }

  .ant-select {
    max-width: 100%;
    width: calc(100% - 30px) !important;
  }

  .custom-radiobox {
    width: 100%;
  }

  .form-box1 .row-panel {
    max-width: 100%;
  }

  .form-box1 .row-panel .row.box-in-mobile {
    margin-left: -10px;
    margin-right: -10px;
  }

  .height-outside {
    font-size: 9px;
    padding-left: 6px;
  }

  .width-outside,
  .btn_group .btn {
    font-size: 9px;
  }
}

@media (max-width: 1041px) {
  .btn_group .btn {
    font-size: 10px;
  }
}

@media (max-width: 991px) {
  .print_sidebar {
    width: 100%;
  }

  .btn_group {
    padding-right: 10px;
    padding-left: 10px;
  }

  .viewcart-btn {
    width: auto;
  }

  .text_area {
    min-height: auto;
  }

  .arrow_icons {
    margin-right: 10px;
  }

  .inner-dropdown {
    max-width: 100%;
  }

  .ant-select {
    max-width: 100%;
    width: calc(100% - 30px) !important;
  }

  .custom-radiobox {
    width: 100%;
  }

  .form-box1 {
    padding-left: 30px;
    width: 100%;
  }

  .form-box1 .row-panel {
    max-width: 100%;
  }

  .form-box1 .row-panel .row.box-in-mobile {
    margin-left: -10px;
    margin-right: -10px;
  }

  .btn-groups .btn {
    font-size: 11px;
  }
}

.main-inner-section {
  position: relative;
  text-align: center;
}

.main-inner-section .preview-area {
  width: auto;
  position: static;
}

.main-inner-section .preview-area .background-first {
  margin: 0;
  -webkit-transform: translate(-15%, -50%);
          transform: translate(-15%, -50%);
  top: 30%;
  right: 15%;
  left: auto;
}

.main-inner-section .preview-area .background-second {
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 30%;
  left: 50%;
}

.home-banner {
  float: left;
  margin-top: -13px;
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
}

/* .home-banner .text_area {
  padding-top: 150px;
}  */

.home-banner .text_area {
  position: relative;
  z-index: 1;
}

img.banner_img {
  /* transform: scale(1.3);
  margin-top: 80px; */
}

.ant-select-selector {
  border-radius: 0 !important;
}

.thumbnail-area .upper {
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 75%;
  top: 29%;
}

.thumbnail-area .upper1 {
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  left: 50%;
  top: 29%;
}

.text-center {
  text-align: center;
}

.ant-col.print_sidebar.side_print_sidebar.ant-col-lg-6 {
  padding: 0 !important;
}

/* .image-backgrounds-div { max-width: 750px; margin: 0 auto;}
.static-block { max-width: 750px;     margin-left: auto;
  margin-right: auto;} */
.image-background1 {
  width: 100%;
}

.main-inner-section {
  max-width: 550px;
  margin: 0 auto;
}

/* .frame_dimension_main{ min-height: 400px;} */
.frame_dimension_display_box_out {
  min-height: 275px;
}

.image-background1 {
  height: 413px;
}

.static-block {
  min-height: 413px;
}

@media (min-width: 1280px) and (max-width: 1400px) {

  /* .image-backgrounds-div { max-width:550px; margin: 0 auto;}
.static-block { max-width: 550px;     margin-left: auto;
  margin-right: auto;} */
  .image-background1 {
    width: 100%;
  }

  .main-inner-section {
    max-width: 550px;
    margin: 0 auto;
  }

  .image-background1 {
    height: 413px;
  }

  .static-block {
    min-height: 413px;
  }

  .frameBlock img {
    height: 413px;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {

  /* .image-backgrounds-div { max-width:400px; margin: 0 auto;}
.static-block { max-width: 400px;     margin-left: auto;
  margin-right: auto;} */
  .main-inner-section {
    max-width: 400px;
    margin: 0 auto;
  }

  .image-background1 {
    width: 100%;
  }

  /* .frameBlock { max-width: 400px; width:100%;} */
  .frameBlock img {
    height: 300px;
    width: 100%;
  }

  .image-background1 {
    height: 300px;
  }

  .static-block {
    min-height: 300px;
  }

  .frameBlock img {
    height: 300px;
  }
}

@media (min-width: 1200px) {
  .home-banner .container {
    max-width: 1300px;
  }

  .header-section .container {
    max-width: 1300px;
    padding-right: 20px;
  }

  .container.container-center {
    max-width: 1300px;
  }
}

@media (max-width: 1200px) {
  .home-banner .container {
    max-width: 1300px;
  }

  .header-section .container {
    max-width: 1300px;
    padding-right: 20px;
  }

  .container.container-center {
    max-width: 1300px;
  }
}

@media (max-width: 1440px) {
  img.banner_img {
    margin-top: 0;
    margin-left: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .thumbnail-area .upper {
    max-width: 30px !important;
    height: 20px !important;
    width: 20px !important;
  }

  .thumbnail-area .upper1 {
    max-width: 30px !important;
    height: 20px !important;
    width: 20px !important;
  }

  .frame_dimension_display_box {
    max-width: 170px;
  }

  img.banner_img {
    margin-left: 0;
  }

  .uploader-div {
    width: 100%;
  }

  .heading-wrapper h1 {
    font-size: 22px;
    line-height: 26px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .perfect-prints-guara {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 32px;
  }

  .card_page .left table tr td {
    white-space: nowrap;
  }

  /* .thumbnail-div img { max-width: 95px;} */
  .thumbnail-div {
    height: 65px;
  }

  .main-inner-section .preview-area .background-second,
  .main-inner-section .preview-area .background-first {
    max-width: 110px !important;
  }

  .frame-dimension-display {
    margin-left: 15px;
  }

  .ant-col-lg-6 {
    display: block;
    flex: 0 0 27%;
    max-width: 27%;
  }

  .ant-col-lg-12 {
    display: block;
    flex: 0 0 46%;
    max-width: 46%;
  }
}

@media (max-width: 991px) {
  img.banner_img {
    margin-top: -11px;
  }

  .main-inner-section .preview-area .background-first,
  .main-inner-section .preview-area .background-second {
    max-width: 80px;
  }
}

@media (min-height: 900px) and (max-height: 1400px) {
  img.banner_img {
    margin-top: 0px;
  }
}

@media (max-width: 991px) {
  .heading-wrapper h1 {
    font-size: 17px;
    line-height: 28px;
  }

  .perfect-prints-guara {
    font-size: 12px;
    line-height: normal;
  }

  .perfect-prints-guara {
    font-size: 18px;
  }

  .home-banner .text_area {
    padding-top: 20px;
  }

  .drag-and-drop-to-upl {
    font-size: 11px;
    line-height: normal;
  }

  .browse-button {
    padding: 0 22px;
  }

  img.banner_img {
    margin-left: 0px;
  }

  .headerLeft {
    margin-left: 0;
  }

  .main-inner-section .preview-area .background-first,
  .main-inner-section .preview-area .background-second {
    max-width: 115px !important;
    width: 115px !important;
  }

  .image-background1 {
    height: auto;
    width: 100%;
  }

  .thumbnail-area .upper1,
  .thumbnail-area .upper {
    top: 22%;
    max-width: 13px !important;
    width: 13px !important;
    height: 13px !important;
  }
}

@media (max-width: 767px) {
  .myfiles {
    padding-inline: 15px;
  }

  .information {
    display: block;
    padding-inline: 15px;
  }

  .frameBlock img {
    height: 279px;
    width: 100%;
  }

  .thumbnail3 {
    width: 66px !important;
  }

  .ant-badge-count {
    right: 0 !important;
  }

  .card_page .left table tr td {
    white-space: nowrap;
  }

  .card_page_top ul li span {
    font-size: 12px;
  }

  img.banner_img {
    display: none;
  }

  .home-banner div[class*="col-"] {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px 0;
  }

  .home-banner {
    margin-top: 0;

    height: calc(100vh - 113px);
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .main-inner-section .preview-area .background-first,
  .main-inner-section .preview-area .background-second {
    max-width: 95px !important;
    width: 95px !important;
  }

  .image-background1 {
    height: auto;
    width: 100%;
  }

  .thumbnail-area .upper1,
  .thumbnail-area .upper {
    top: 35%;
    max-width: 10% !important;
    width: 13px !important;
    height: 13px !important;
    -webkit-transform: translate(-50%, -35%);
            transform: translate(-50%, -35%);
  }

  .toast {
    width: 100% !important;
  }

  .cropper-toaster {
    top: 50% !important;

    -webkit-transform: translateY(-50%);

            transform: translateY(-50%);
  }
}

@media (max-width: 576px) {

  .upper-division .upper-border-box,
  .upper-division .upper-frame-box,
  .upper-division .upper-matting-box {
    justify-content: center;
    align-items: center;
  }

  .home-banner {
    margin-top: 0;

    height: calc(100vh - 170px);
  }

  .browse-button {
    height: 42px;

    font-size: 14px;
  }

  .uploader-div {
    padding: 20px !important;
  }

  .heading-wrapper h1 {
    font-size: 16px;
    line-height: 28px;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20px;
  }
}

@media (max-width: 300px) {
  .home-banner {
    margin-top: 0;

    height: calc(100vh - 136px);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.print-label-size ul li span.sign-cross {
  color: rgba(0, 0, 0, 0.4);
  font-size: 10px;
}

@media (min-height: 700px) and (max-height: 754px) {
  img.banner_img {
    margin-top: 0;
  }
}

@media (min-height: 500px) and (max-height: 620px) {
  .uploader-div {
    padding: 10px 20px !important;
  }

  .browse-button {
    height: 35px;

    font-size: 14px;
  }
}

.frame_dimension_display_box {
  text-align: center;
  max-width: 200px;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.left-identity-value {
  float: left;
  width: 50px;
  position: absolute;

  top: 0;
  bottom: 34px;

  left: -48px;
  text-align: right;
}

.height-outside {
  position: absolute;
  left: -47px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  left: auto !important;
  text-align: right;
  width: 100%;
}

.frame_dimension_display_box_out {
  text-align: center;
}

@media (max-width: 1199px) {
  .height-outside {
    font-size: 9px;
  }

  .left-identity-value {
    left: -55px;
  }
}

@media (min-width: 280px) and (max-width: 1024px) {
  .card_page .col-sm-8 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .card_page .col-sm-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .card_page .left table tr td {
    white-space: nowrap;
  }
}

.card_page .left table tr th {
  white-space: nowrap;
}

@media (min-width: 1020px) and (max-width: 1024px) {
  .text_area {
    min-height: 100%;

    margin-top: 50px;
  }
}

@media (min-height: 500px) and (max-height: 512px) {
  .home-banner {
    margin-top: 0;
    height: 100%;
  }
}

@media (min-height: 280px) and (max-height: 414px) {
  .home-banner {
    margin-top: 0;
    height: 100%;
  }
}

.waitloader {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;

  width: 100%;
  flex-direction: column;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .frame_dimension_display_box {
    max-width: 170px;
  }
}

#file-uploader {
  display: none;
}
.back-btn{
    position: relative;
    top:4rem;
    text-decoration:none;
    color:black
}
.back-btn:hover{
    text-decoration:none;
    color:black
}
@media (max-width: 767px) { 
    .back-btn {
        display: none;
    }
 }
.continue-btn{
    width: 100% !important;
    border: 2px solid #31A9F3 !important;
    background-color: #FFFFFF !important;
    min-height: 54px !important;
    border-radius: 0 !important;
    color: #31A9F3 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.87px !important;
    line-height: 24px !important;
    text-align: center !important;
}
.continue-btn:hover{
    width: 100% !important;
    border: 2px solid #31A9F3 !important;
    background-color: #31A9F3 !important;
    min-height: 54px !important;
    border-radius: 0 !important;
    color: #FFFFFF !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.87px !important;
    line-height: 24px !important;
    text-align: center !important;
}
.continue-btn:focus{
    width: 100% !important;
    border: 2px solid #31A9F3 !important;
    background-color: #31A9F3 !important;
    min-height: 54px !important;
    border-radius: 0 !important;
    color: #FFFFFF;
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.87px !important;
    line-height: 24px !important;
    text-align: center !important;
}
.cartIsEmpty {
    text-align: center;
    padding: 8rem;
    font-family: inherit;
    font-size: 1.5rem;
    color: #31A9F3;
}
label{
    font-weight: 800;
    margin-right: 2px;
    padding-bottom: 0;
    margin-bottom: 0;
}
._id{
    display: none;
}
.modify_link{
    text-decoration: underline;
    color: #007BFF;
    cursor:pointer
}
.link{
    color: #007BFF
}
.text_decoration_none{
    text-decoration: none;
}
.text_decoration_none:hover{
    text-decoration: none;
}
